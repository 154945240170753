import { IStoreAction } from 'network/config';
import { SET_ALL_TRANSLATED_MSGS } from '../storeTypes';

export type ITranslatedMsg = {
    _id: string;
    type: string;
    english: string;
    arabic: string;
    deutsch: string;
};
export interface ITranslationReducer {
    translatedMsgs: ITranslatedMsg[];
}

const translationReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_ALL_TRANSLATED_MSGS:
            return {
                ...state,
                translatedMsgs: action.payload,
            };
        default:
            return state;
    }
};

export default translationReducer;
