import { Dispatch } from 'redux';
import {
    apiAction,
    replaceUrlVariables,
    setToLocalStorage,
} from 'network/config/middleware/api.middleware.helper';
import {
    GET_CHANGE_PASSWORD,
    GET_DISABLE_USERS,
    GET_LIST_ALL_CAT_WITH_PERMISSIONS,
    GET_LOGIN_DATA,
    GET_SHOW_USER_PERMISSIONS,
    GET_UPDATE_USER_PERMISSIONS,
    GET_USER_ROLE,
    GET_VERIFY_USER,
    SET_CHANGE_PASSWORD,
    SET_DISABLE_USERS,
    SET_LIST_ALL_CAT_WITH_PERMISSIONS,
    SET_LOGIN_DATA,
    SET_SHOW_USER_PERMISSIONS,
    SET_UPDATE_USER_PERMISSIONS,
    SET_USER_ROLE,
    SET_VERIFY_USER,
} from 'network/store/storeTypes';
import {
    AUTH_VERIFY,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_CHANGE_PASSWORD,
} from 'network/store/storeTypes';
import { ADMIN, AUTH } from 'network/config/allEndPoints';

export const login = (email: string, password: string) => (dispatch: Dispatch) => {
    const url = AUTH.LOGIN;
    dispatch(
        apiAction({
            url,
            method: 'POST',
            apiBase: 'CRYPTONAIRE',
            data: {
                email: email,
                password: password,
            },
            label: GET_LOGIN_DATA,
            onSuccess: (result) => (dispatch: Dispatch) => {
                try {
                    setToLocalStorage('crypto_user', JSON.stringify(result));
                    setToLocalStorage('token', result?.data?.token);
                    setToLocalStorage('email', result?.data?.profile?.email);
                } catch (err) {
                    console.log(err);
                }

                dispatch({
                    type: SET_LOGIN_DATA,
                    payload: result.data,
                });
            },
        }),
    );
};

export const verifyToken = (token: string) =>
    apiAction({
        url: '',
        label: AUTH_VERIFY,
        method: 'POST',
        data: {
            token,
        },
        apiBase: 'CRYPTONAIRE',
        onSuccess: (result) => ({
            type: AUTH_LOGIN,
            payload: result,
        }),
    });

export const logout = () => (dispatch: Dispatch) => {
    dispatch({ type: AUTH_LOGOUT });
    if (process.env.NODE_ENV !== 'development') {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('email');
        if (window.top) {
            window.top.location.href = '/';
        }
    }
};

export const getRole = () => (dispatch: Dispatch) => {
    const url = AUTH.GET_ROLE;
    dispatch(
        apiAction({
            url,
            method: 'GET',
            apiBase: 'CRYPTONAIRE',
            label: GET_USER_ROLE,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_USER_ROLE, payload: result.data }),
        }),
    );
};

export const showUserPermissions = (userId: string) => (dispatch: Dispatch) => {
    const url = replaceUrlVariables(ADMIN.SHOW_USER_PERMISSIONS, {
        userId: userId,
    });
    dispatch(
        apiAction({
            url,
            method: 'GET',
            apiBase: 'CRYPTONAIRE',
            label: GET_SHOW_USER_PERMISSIONS,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_SHOW_USER_PERMISSIONS, payload: result.data }),
        }),
    );
};
export const updateUserPermissions = (userId: string, data: any) => (dispatch: Dispatch) => {
    const url = replaceUrlVariables(ADMIN.UPDATE_USER_PERMISSIONS, {
        userId: userId,
    });
    dispatch(
        apiAction({
            url,
            method: 'POST',
            data: data,
            apiBase: 'CRYPTONAIRE',
            label: GET_UPDATE_USER_PERMISSIONS,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_UPDATE_USER_PERMISSIONS, payload: result.data }),
        }),
    );
};

export const changePasswordFC =
    (oldPassword: string, newPassword: string) => (dispatch: Dispatch) => {
        const url = AUTH.CHANGE_PASSWORD;
        dispatch(
            apiAction({
                url,
                method: 'POST',
                apiBase: 'CRYPTONAIRE',
                data: { oldPassword: oldPassword, newPassword: newPassword },
                label: GET_CHANGE_PASSWORD,
                onSuccess: (result) => (dispatch: Dispatch) =>
                    dispatch({ type: SET_CHANGE_PASSWORD, payload: result.data }),
            }),
        );
    };

export const activateUser = (token: string) => (dispatch: Dispatch) => {
    const url = `${AUTH.VERIFY_BY_ADMIN}/${token}`;
    dispatch(
        apiAction({
            url,
            method: 'GET',
            apiBase: 'CRYPTONAIRE',
            label: GET_VERIFY_USER,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_VERIFY_USER, payload: result.data }),
        }),
    );
};

export const listAllCatWithPermissions = () => (dispatch: Dispatch) => {
    const url = ADMIN.LIST_ALL_CAT_WITH_PERMISSIONS;
    dispatch(
        apiAction({
            url,
            method: 'GET',
            apiBase: 'CRYPTONAIRE',
            label: GET_LIST_ALL_CAT_WITH_PERMISSIONS,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_LIST_ALL_CAT_WITH_PERMISSIONS, payload: result.data }),
        }),
    );
};

export const disableUsers = (data: any) => (dispatch: Dispatch) => {
    const url = ADMIN.CHANGE_USER_STATUS;
    dispatch(
        apiAction({
            url,
            method: 'PATCH',
            apiBase: 'CRYPTONAIRE',
            data: data,
            label: GET_DISABLE_USERS,
            onSuccess: (result) => (dispatch: Dispatch) =>
                dispatch({ type: SET_DISABLE_USERS, payload: result.data }),
        }),
    );
};
