import { IStoreAction } from 'network/config';
import {
    SET_ACTIVE_QUIZZES,
    SET_ALL_WALLETS,
    SET_CLAIMED_USERS,
    SET_LEADERBOARD_INFORMATION,
    SET_LEADER_BOARD,
    SET_QUIZ_CATEGORIES,
    SET_REST_BALANCE,
    SET_REST_USER_XP,
    SET_USER_CLAIM_DETAILS,
} from '../storeTypes';
import { IToken } from './wheelFotuneReducer';

export type TCategory = {
    cryptoChallenge?: boolean;
    dailyQuiz: boolean;
    dailyQuizTime?: string;
    dailyWeekDay?: string;
    description?: string;
    lastDistributedAt?: string;
    endDate?: string;
    startDate?: string;
    logo?: string;
    name: string;
    sponsoredBy?: string;
    sponsoredImage?: string;
    updatedAt?: string;
    _id: string;
};

type TBalances = {
    token: string;
    balance: number;
};
type TType = {
    _id: string;
    type: string;
    createdAt: string;
    isActive: boolean;
    updatedAt: string;
};
export type TWallet = {
    address: string;
    typeId: string;
    createdAt: string;
    isActive: boolean;
    updatedAt: string;
    userId: string;
    _id: string;
};

type TChildUser = {
    avatar: string;
    balances: TBalances[];
    email: string;
    isActive: boolean;
    overall_xp: number;
    user: {
        _id: string;
        activeEmailToken: any;
        avatar: string;
        balances: TBalances[];
        createdAt: string;
        email: string;
        hash: string;
        isActive: boolean;
        isAdmin: boolean;
        lastPasswordUpdatedAt: string;
        overall_xp: number;
        salt: string;
        typeId: TType[];
        updateAt: string;
        username: string;
        wallets: TWallet[];
    };
    username: string;
    _id: string;
};

export type TXps = {
    category: string;
    name: string;
    daily: number;
    weekly: number;
    all_time: number;
    _id: string;
};

export type TUser = {
    user: TChildUser;
    _id: string;
    xp: number;
    xps: TXps[];
};

type TReward = {
    afterFifth: number;
    category: string;
    fifth: number;
    first: number;
    fourth: number;
    second: number;
    third: number;
    token: {
        tokenAddress: string;
        tokenImage: string;
        tokenName: string;
        updatedAt: string;
        _id: string;
    };
    updatedAt: string;
    _id: string;
};

export interface IActiveQuiz {
    category: {
        dailyQuizTime: string;
        dailyWeekDay: string;
        logo: string;
        name: string;
        sponsoredBy: string;
        sponsoredImage: string;
        _id: string;
        endDate: string;
        startDate: string;
    };
    isDailyQuiz: boolean;
    remainingAttempts: number;
}

type TLeaderboardInfo = {
    dailyQuizTakenToday: number;
    numberOfActiveUsersToday: number;
    numberOfTotalUsersToday: number;
    numberOfcryptoChallenge: number;
    quizSpentPeppers: number;
    spinSpentPeppers: number;
};

interface ILeaderboard {
    category: TCategory;
    dailyQuizTakenToday: number;
    endDate: any;
    startDate: any;
    leaderBoard: TUser[];
    numberOfActiveUsersToday: number;
    numberOfTotalUsersToday: number;
    numberOfcryptoChallenge: number;
    peeperUsedToday: number;
    reward: TReward;
}

type TWalletType = {
    createAt: string;
    image: string;
    type: string;
    updatedAt: string;
    _id: string;
};

export interface IWallet {
    walletAddress: string;
    walletId: string;
    walletName: string;
    _id: string;
}
export interface IClaimedUser {
    claimedValue: number;
    createAt: string;
    email: string;
    ipAddress: string;
    isClaimed: boolean;
    position: number;
    tokenId: string;
    tokenName: string;
    updateAt: string;
    userId: string;
    username: string;
    wallets: IWallet[];
}

export interface IClaims {
    data: IClaimedUser[];
    disabledUsers: IClaimedUser[];
    tokens: IToken[];
    totalUsers: number;
}

export interface IUserClaims {
    claimedValue: number;
    createdAt: string;
    updatedAt: string;
    email: string;
    ipAddress: string;
    isClaimed: boolean;
    position: number;
    tokenId: string;
    tokenName: string;
    updateAt: string;
    userId: string;
    username: string;
    wallets: IWallet[];
    polygon: string;
    shimmer: string;
    casper: string;
}

export interface ILeaderboardReducer {
    leaderboard: ILeaderboard;
    categories: TCategory[];
    leaderboardInfo: TLeaderboardInfo;
    resetBalance: any;
    resetXp: any;
    wallets: TWalletType[];
    activeQuizzes: IActiveQuiz[];
    claimedUsers: IClaims;
    userClaims: IUserClaims[];
}

const leaderboardReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_LEADER_BOARD:
            return {
                ...state,
                leaderboard: action.payload,
            };
        //Get Categories
        case SET_QUIZ_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        //Get Leaderboard Info
        case SET_LEADERBOARD_INFORMATION:
            return {
                ...state,
                leaderboardInfo: action.payload,
            };
        //Reset Balance
        case SET_REST_BALANCE:
            return {
                ...state,
                resetBalance: action.payload,
            };
        //Reset XP
        case SET_REST_USER_XP:
            return {
                ...state,
                resetXp: action.payload,
            };
        //Wallet
        case SET_ALL_WALLETS:
            return {
                ...state,
                wallets: action.payload,
            };
        //Claimed Users
        case SET_CLAIMED_USERS:
            return {
                ...state,
                claimedUsers: action.payload,
            };
        case SET_ACTIVE_QUIZZES:
            return {
                ...state,
                activeQuizzes: action.payload,
            };
        case SET_USER_CLAIM_DETAILS:
            return {
                ...state,
                userClaims: action.payload,
            };
        default:
            return state;
    }
};

export default leaderboardReducer;
