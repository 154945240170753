import ReactLoading from 'react-loading';
import { Grid } from '@mui/material';

export default function CustomSkeleton() {
    return (
        <Grid container wrap="nowrap" height="75vh" justifyContent="center" alignItems="center">
            <ReactLoading type="spinningBubbles" color="#0000FF" height={70} width={70} />
        </Grid>
    );
}
