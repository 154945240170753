import { IStoreAction } from 'network/config';
import {
    SET_ALL_IP_ADDRESSES,
    SET_ALL_USERS_DETAILS,
    SET_APP_STATUS,
    SET_DUPLICATED_WALLETS,
    SET_REFERRED_BY_INFLUENCER_DETAILS,
    SET_USER_DETAILS,
    SET_USER_QUIZ_ATTEMPTS,
} from '../storeTypes';

export type TToken = {
    createdAt: string;
    tokenAddress: string;
    tokenImage: string;
    tokenName: string;
    updatedAt: string;
    _id: string;
};
export type TBalance = {
    token: TToken;
    value: number;
    _id: string;
};

export type TXps = {
    category: string;
    name: string;
    daily: number;
    weekly: number;
    all_time: number;
    _id: string;
};

type TTypeId = {
    _id: string;
    type: string;
    isActive: boolean;
    image: string;
};
export type TWallet = {
    address: string;
    createdAt: string;
    isActive: boolean;
    typeId: TTypeId;
    _id: string;
};

export type TActivePasswordToken = {
    token: string;
    expires: string;
};
export interface IUser {
    _id: string;
    avatar: string;
    balance: TBalance[];
    createdAt: string;
    updatedAt: string;
    isAdmin: boolean;
    isActive: boolean;
    email: string;
    username: string;
    discord_username: string;
    lastPasswordUpdatedAt: string;
    overall_xp: number;
    activeEmailToken?: TActivePasswordToken;
    hash: string;
    resetPasswordToken: any;
    salt: string;
    wallets: TWallet[];
    xps: TXps[];
    ipAddress: string;
    referredBy: string;
    status: {
        enabled: boolean;
        note: string;
    };
    connectedWalletAddress: string;
}
export interface IUserDetails {
    avatar: string;
    balances: TBalance[];
    createdAt: string;
    updatedAt: string;
    isAdmin: boolean;
    isActive: boolean;
    email: string;
    username: string;
    discord_username: string;
    lastPasswordUpdatedAt: string;
    overall_xp: number;
    activeEmailToken?: TActivePasswordToken;
    hash: string;
    resetPasswordToken: any;
    salt: string;
    user: IUser;
    wallets: TWallet[];
}

export interface IUserQuizAttempt {
    _id: string;
    quiz: string;
    type: string;
    xp: number;
    category: string;
    correctAnswers: number;
    wrongAnswers: number;
    createdAt: string;
    updatedAt: string;
}

export interface IAppStatus {
    app_paused: boolean;
    isChristmasHere: boolean;
    _id: string;
}
export interface IGeneralDataState {
    userDetails: IUserDetails;
    usersReferredByInfluencer: IUser[];
    userQuizAttempts: IUserQuizAttempt[];
    users: IUser[];
    duplicatedWallets: IUser[];
    ipAddresses: string[];
    appStatus: IAppStatus;
}

const generalReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case SET_USER_QUIZ_ATTEMPTS:
            return {
                ...state,
                userQuizAttempts: action.payload,
            };
        case SET_ALL_USERS_DETAILS:
            return {
                ...state,
                users: action.payload,
            };
        case SET_DUPLICATED_WALLETS:
            return {
                ...state,
                duplicatedWallets: action.payload,
            };
        case SET_ALL_IP_ADDRESSES:
            return {
                ...state,
                ipAddresses: action.payload,
            };

        case SET_REFERRED_BY_INFLUENCER_DETAILS:
            return {
                ...state,
                usersReferredByInfluencer: action.payload,
            };
        case SET_APP_STATUS:
            return {
                ...state,
                appStatus: action.payload,
            };
        default:
            return state;
    }
};

export default generalReducer;
