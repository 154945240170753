import { IStoreAction } from 'network/config';
import {
    SET_ADD_EDIT_REFERRAL_REWARD_ERROR,
    SET_ADD_EDIT_REFERRAL_REWARD_SUCCESS,
    SET_REFERRAL_REWARDS,
    SET_REFERRED_FRIENDS,
    SET_REFERRED_USERS,
    SET_REGISTERED_USERS_USING_REFERRAL_CODE,
    SET_SINGLE_REFERRAL_REWARD,
    SET_USER_REFERRAL_DETAILS,
} from '../storeTypes';

export interface IReferredFriend {
    avatar: string;
    username: string;
    email: string;
    created: boolean;
    confirmed: boolean;
}

export interface IReferralReward {
    type: string;
    name: string;
    tokenId: string;
    percentage: number;
    value: number;
    _id: string;
    cover: string;
    logo: string;
}
export interface IReferredFriends {
    referredUsers: IReferredFriend[];
    referralCode: string;
}

export interface IMessage {
    message?: string;
}

export interface IReferredUser {
    userId: string;
    username: string;
    email: string;
    avatar: string;
    referredFriendsNo: number;
    prizesNo: number;
    ipAddress: string;
}
export interface IReferredUsersDetails {
    username: string;
    email: string;
    avatar: string;
    confirmed: string;
    createdAt: string;
    isScratched: boolean | string;
    prizeType: string;
    prizeName: string;
    prizeValue: string | number;
    ipAddress: string;
}

export interface IUserReferralDetails {
    referredUser: string;
    referredUserAvatar: string;
    referredUsers: IReferredUsersDetails[];
}

export interface IRegUsingRefCode {
    userId: string;
    username: string;
    email: string;
    avatar: string;
    prizeType: string;
    prizeName: string;
    prizeValue: string;
    prizeLogo: string;
    referredBy: string;
    referredCode: string;
    ipAddress: string;
}
export interface IReferral {
    referredFriends: IReferredFriends;
    referralRewards: IReferralReward[];
    reward: IReferralReward;
    rewardErrorMessage: IMessage;
    rewardSuccessMessage: IMessage;
    referredUsers: { usersData: IReferredUser[]; pagesNo: number };
    userReferralDetails: IUserReferralDetails;
    regUsingRefCode: IRegUsingRefCode[];
}

const referralReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_REFERRED_FRIENDS:
            return {
                ...state,
                referredFriends: action.payload,
            };
        case SET_REFERRAL_REWARDS:
            return {
                ...state,
                referralRewards: action.payload,
            };
        case SET_SINGLE_REFERRAL_REWARD:
            return {
                ...state,
                reward: action.payload,
            };
        case SET_ADD_EDIT_REFERRAL_REWARD_ERROR:
            return {
                ...state,
                rewardErrorMessage: action.payload,
            };
        case SET_ADD_EDIT_REFERRAL_REWARD_SUCCESS:
            return {
                ...state,
                rewardSuccessMessage: action.payload,
            };
        case SET_REFERRED_USERS:
            return {
                ...state,
                referredUsers: action.payload,
            };
        case SET_USER_REFERRAL_DETAILS:
            return {
                ...state,
                userReferralDetails: action.payload,
            };
        case SET_REGISTERED_USERS_USING_REFERRAL_CODE:
            return {
                ...state,
                regUsingRefCode: action.payload,
            };
        default:
            return state;
    }
};

export default referralReducer;
