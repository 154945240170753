import { IStoreAction } from 'network/config';
import {
    SET_LIST_ALL_CAT_WITH_PERMISSIONS,
    SET_LOGIN_DATA,
    SET_SHOW_USER_PERMISSIONS,
    SET_SIDE_BAR,
    SET_USER_ROLE,
} from 'network/store/storeTypes';

type TUser = {
    profile: { avatar: string; email: string; username: string; wallets: any[] };
    token: string;
};

export type TRole = {
    _id: string;
    userId: string;
    role: string;
    permissions: string[];
};

export interface ICatWithPermissions {
    _id: string;
    category: string;
    permissions: string[];
}
export interface IUserState {
    user: TUser;
    showSideBar: boolean;
    role: TRole;
    catsWithPermissions: ICatWithPermissions[];
    userPermissions: TRole;
}

const authUserReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_LOGIN_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case SET_SIDE_BAR:
            return {
                ...state,
                showSideBar: action.payload,
            };
        case SET_USER_ROLE:
            return {
                ...state,
                role: action.payload,
            };
        case SET_LIST_ALL_CAT_WITH_PERMISSIONS:
            return {
                ...state,
                catsWithPermissions: action.payload,
            };
        case SET_SHOW_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.payload,
            };
        default:
            return state;
    }
};

export default authUserReducer;
