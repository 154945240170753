const API_CONFIG: { [key: string]: any } = {
    baseURL: {
        CRYPTONAIRE: process.env.REACT_APP_CRYPTONAIRE_API_URL,
        None: '/',
    },
    API_HEADERS_COMMON_CONFIG: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    DEFAULT_TOKEN_TYPE: 'Bearer',
};

export default API_CONFIG;
