import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import apiMiddleware from './middleware/api.middleware';
import { combineReducers } from 'redux';
import apiReducer from './reducers/apiReducers';
import authReducer from './reducers/authReducers';
import { composeWithDevTools } from '@redux-devtools/extension';
import { IStoreAction } from './storeInterface';

const storeInit = (
    logoutIdentifier: string,
    initialState = {},
    reducers = {},
    additionalMiddlewares = [],
    additionalEnhancers = [],
) => {
    const middlewares = [...additionalMiddlewares, apiMiddleware, thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const storeEnhancers = [middlewareEnhancer] as any[];
    if (process.env.NODE_ENV === 'development') {
        const devTools = composeWithDevTools();
        if (devTools) {
            storeEnhancers.push(devTools);
        }
    }

    const appReducer = combineReducers({
        api: apiReducer,
        auth: authReducer,
        ...reducers,
    });
    const rootReducer = (state: any, action: IStoreAction) => {
        if (action.type === logoutIdentifier) {
            state = undefined;
        }

        return appReducer(state, action);
    };

    const composedEnhancers = compose(...storeEnhancers, ...additionalEnhancers) as any;

    return createStore(rootReducer, initialState, composedEnhancers);
};

export default storeInit;
