import QuestionsReducer from './QuestionsReducer';
import authUserReducer from './authReducer';
import flashLearnReducer from './flashLearnReducer';
import generalReducers from './generalReducers';
import leaderboardReducer from './leaderboardReducer';
import translationReducer from './translationReducer';
import referralReducer from './referralReducer';
import RewardReducer from './rewardReducer';
import wheelFortuneReducer from './wheelFotuneReducer';
import notificationsReducer from './notificationsReducer';

const reducers = {
    general: generalReducers,
    authUser: authUserReducer,
    leaderboard: leaderboardReducer,
    flashLearn: flashLearnReducer,
    wheelFortune: wheelFortuneReducer,
    questions: QuestionsReducer,
    rewards: RewardReducer,
    referral: referralReducer,
    translations: translationReducer,
    notifications: notificationsReducer,
};

export default reducers;
