import { IStoreAction } from 'network/config';
import {
    SET_ALL_QUESTIONS,
    SET_QUESTIONS_ANALYTICS_DETAILS,
    SET_QUESTIONS_PAGE_NUMBER,
    SET_REPORTED_QUESTIONS,
    SET_SINGLE_QUESTIONS,
} from '../storeTypes';
import { TCategory } from './leaderboardReducer';

export interface IOption {
    optionA: boolean | string;
    optionB: boolean | string;
    optionC: string;
    optionD: string;
    answer: boolean | string;
}

export interface IQuestionDetails {
    questionId: string;
    category: string;
    appeared: number;
    averageTime: string;
    questionText: string;
    difficulty: string;
    type: string;
    optionA: string | boolean;
    optionB: string | boolean;
    optionC: string;
    optionD: string;
    answeredCorrectly: number;
    answeredWrong: number;
    correctAnswer: string | boolean;
    userAnswer: string | boolean;
    spentTime: number;
}
export interface IQuestion {
    _id: string;
    category: TCategory;
    options: IOption;
    questionText: string;
    type: string;
    difficulty: string;
    createdAt: string;
    updatedAt: string;
    englishId: string;
    isTranslated: boolean;
    isTranslatedToAr: boolean;
    isTranslatedToDe: boolean;
}
export interface ISingleQuestion {
    question: {
        arabicQuestion: IQuestion;
        englishQuestion: IQuestion;
        deutchQuestion: IQuestion;
    };
    options: {
        arabicOptions: IOption;
        englishOptions: IOption;
        deutchOptions: IOption;
    };
}

export type TReportedByUser = {
    username: string;
    email: string;
    avatar: string;
};
export interface IReportedQuestion {
    questionId: string;
    category: string;
    appeared: number;
    questionText: string;
    difficulty: string;
    type: string;
    reviewed: boolean;
    reportedByUsers: TReportedByUser[];
}

export interface IQuestionAnalyticsDetails {
    questionsDetails: IQuestionDetails[];
    pagesNo: number;
}
export interface TQuestionPage {
    activeKey: number;
    activePage: number;
    category: TCategory;
    lang: string;
}
export interface IQuestionsReducer {
    questions: IQuestion[];
    question: ISingleQuestion;
    questionAnalytics: IQuestionAnalyticsDetails;
    reportedQuestions: IReportedQuestion[];
    questionPage: TQuestionPage;
}

const QuestionsReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_ALL_QUESTIONS:
            return {
                ...state,
                questions: action.payload,
            };
        case SET_SINGLE_QUESTIONS:
            return {
                ...state,
                question: action.payload,
            };
        case SET_REPORTED_QUESTIONS:
            return {
                ...state,
                reportedQuestions: action.payload,
            };
        case SET_QUESTIONS_ANALYTICS_DETAILS:
            return {
                ...state,
                questionAnalytics: action.payload,
            };
        case SET_QUESTIONS_PAGE_NUMBER: {
            return {
                ...state,
                questionPage: action.payload,
            };
        }
        default:
            return state;
    }
};

export default QuestionsReducer;
