import { apiError, apiStart, apiEnd } from '../actions/apiActions';
import { API } from '../storeTypes';
import { NetworkRequest, NetworkMultipleRequests } from './api.middleware.helper';
import axios from 'axios';
import { MiddlewareAPI } from 'redux';
import { IApiMiddlewareAction, IApiMultiPayload, IApiPayload } from '../storeInterface';
import { Dispatch } from 'react';
import { IState } from '..';

const apiMiddleware =
    ({ dispatch, getState }: MiddlewareAPI<any>) =>
    (next: Dispatch<IApiMiddlewareAction>) =>
    async (action: any) => {
        try {
            next(action);
        } catch (err) {
            console.log('action');
        }
        if (action.type !== API) return;

        const {
            url,
            method,
            data,
            onSuccess,
            onFailure,
            label = '',
            apiBase,
            requests,
            withToken,
            token,
            withReqCode,
            isAbsoluteUrl,
            headers,
            onFinaly,
            tokenType,
        } = action.payload as IApiPayload & IApiMultiPayload;

        const { api = {} } = getState() as IState;

        const { isLoading } = api[label] || {};

        /**
         * assign the Token to a variable to make it ready for injection if exist
         */
        let updatedToken: string | undefined;

        if (requests && requests.length > 0 && !isLoading) {
            if (label && !isLoading) {
                dispatch(apiStart(label));
            }

            let updatedRequests = requests;

            /**
             * if the updated token is not undefined
             * then we have the af token , we loop into each request and see if the
             * withToken is enabled and the apiBase is af then inject the token to the request
             */
            if (updatedToken) {
                updatedRequests = requests.map((request) => request);
            }

            /**
             * in case the requests is available and have data
             * do multiple network requests
             * request data should include
             * @param url
             * @param method
             * @param data
             * @param withToken default to true
             * @param token to override the default process of fetching the token
             */
            NetworkMultipleRequests({ requests: updatedRequests })
                .then((responses) => {
                    const transformedResponses = responses.map((response) => {
                        if (response.data) {
                            return response.data;
                        }
                        return { error: response };
                    });
                    if (onSuccess) {
                        dispatch(onSuccess(transformedResponses));
                    }
                    dispatch(apiEnd(label, ''));
                })
                .catch((error) => {
                    dispatch(apiError(error, label));
                    if (onFailure) {
                        dispatch(onFailure(error));
                    }
                })
                .finally(() => {
                    if (onFinaly) {
                        dispatch(onFinaly());
                    }
                });
        } else if (!isLoading) {
            if (label) {
                dispatch(apiStart(label));
            }

            /**
             * in case the data need reqCode we need to fetch it
             * (currently used in login and register only in auth app)
             */
            let finalDataToSend;
            if (withReqCode) {
                let response;
                response = await axios.get('');

                const { data: responseData } = response;
                if (responseData) {
                    const { data: reqCodeData = {} } = responseData as any;
                    const { code } = reqCodeData as any;
                    finalDataToSend = { ...data, reqCode: code };
                }
            } else {
                finalDataToSend = { ...data };
            }

            const networkRequestData = {
                apiBase,
                method,
                url,
                data: finalDataToSend,
                headers,
                isAbsoluteUrl,
                token,
                withReqCode,
                withToken,
                tokenType,
            };

            /**
             * we check if the apiBase is af and withToken is enabled
             * and the Token is available by checking updatedToken is not undefined
             * then inject the token to the request
             */

            NetworkRequest(networkRequestData)
                .then((response) => {
                    const { data } = response;

                    dispatch(apiEnd(label, data.message));

                    try {
                        if (onSuccess) {
                            dispatch(onSuccess(data, response));
                        }
                    } catch (err) {
                        console.log('error : ', err);
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                    dispatch(apiError(error, label));
                    if (onFailure) {
                        dispatch(onFailure(error));
                    }
                })
                .finally(() => {
                    if (onFinaly) {
                        dispatch(onFinaly());
                    }
                });
        }
    };

export default apiMiddleware;
