import { IStoreAction } from 'network/config';
import {
    SET_ALL_REWARDS_DETAILS,
    SET_DISTRIBUTE_REWARD,
    SET_USERS_WALLET_ADDRESSES,
} from 'network/store/storeTypes';
import { TCategory } from './leaderboardReducer';

type TToken = {
    createdAt: string;
    tokenAddress: string;
    tokenName: string;
    updatedAt: string;
    _id: string;
};

export interface IReward {
    category: TCategory;
    token: TToken;
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
    afterFifth: number;
    sixthToTen: number;
    elevenToTwenty: number;
    twentyOneToFifty: number;
    fiftyOneTo200: number;
    beyond201: number;
    beyond301: number;
    beyond401: number;
    beyond501: number;
    beyond601: number;
    beyond701: number;
    beyond801: number;
    beyond901: number;
    noOfWinners: number;
    updatedAt: string;
    createdAt: string;
    _id: string;
}

export interface IUserWalletAddress {
    username: string;
    email: string;
    walletAddress: string;
    type: string;
}
export interface IRewardState {
    rewards: IReward[];
    distributeReward: string;
    userWalletAddresses: IUserWalletAddress[];
}

const RewardReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_ALL_REWARDS_DETAILS:
            return {
                ...state,
                rewards: action.payload,
            };
        case SET_DISTRIBUTE_REWARD:
            return {
                ...state,
                distributeReward: action.payload,
            };
        case SET_USERS_WALLET_ADDRESSES:
            return {
                ...state,
                userWalletAddresses: action.payload,
            };
        default:
            return state;
    }
};

export default RewardReducer;
