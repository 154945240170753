import React, { Suspense, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Protected from './protected';
import './scss/style.scss';
import CustomSkeleton from 'components/CustomSkeleton/CustomSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getRole } from 'network/store/actions/authActions';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRole());
    }, [dispatch]);
    return (
        <HashRouter>
            <Suspense fallback={<CustomSkeleton />}>
                <Routes>
                    <Route path="*" name="Home" element={<Protected Component={DefaultLayout} />} />
                    <Route path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    );
};

export default App;
