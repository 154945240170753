import { IStoreAction } from 'network/config';
import {
    SET_ALL_REMAINING_SLOTS,
    SET_ALL_SPINED_USERS,
    SET_ALL_TOKENS,
    SET_ALL_WHEEL_SLOTS,
    SET_SINGLE_WHEEL_SLOTS,
    SET_SLOTS_NO,
    SET_USER_SPINS,
    SET_WHEEL_FORTUNE_STATISTICS,
} from '../storeTypes';

export interface ISlot {
    _id: string;
    tokenId: string;
    logo: string;
    tokenName: string;
    slots: number;
    tokens: number;
    priority: number;
    createdAt: string;
    updatedAt: string;
    positions: number[];
    color: string;
    forCryptoChefsNft: boolean;
}

export interface IToken {
    _id: string;
    createdAt?: string;
    tokenAddress: string;
    tokenImage: string;
    tokenName: string;
    updatedAt?: string;
}

export type TUserSlot = {
    tokenId: string;
    tokenName: string;
    tokens: number;
};
export interface IRemainingSlots {
    randomSlots: TUserSlot[];
    slotsCount: number;
}

export interface ISlots {
    wheelFortunes: ISlot[];
    usedPositions: number[];
}

type TSlotsNo = {
    name: string;
    position: number;
};

export interface IUserSpin {
    tokenId: string;
    tokenName: string;
    tokens: string;
    cost: string;
    date: string;
    _id: string;
}

export interface IUserForSpin {
    avatar: string;

    email: string;

    username: string;
}

interface IUserSpins {
    spins: IUserSpin[];
    user: IUserForSpin;
}

export type TLatestSpin = {
    token: string;
    prize: number;
    cost: string;
};

export interface IAllSpinedUsers {
    username: string;
    userId: string;
    noOfSpins: number;
    latestSpinResult: TLatestSpin;
    latestAttemptDate: string;
}
export interface ISpinedUser {
    pagesNo: number;
    allUsers: IAllSpinedUsers[];
}

export interface IStatistic {
    repeated: number;
    tokenName: string;
    tokens: number;
    percentage: string;
}
export interface IWheelFortuneReducer {
    slots: ISlots;
    tokens: IToken[];
    slot: ISlot;
    remainingSlots: IRemainingSlots;
    slotsNo: TSlotsNo[];
    userSpins: IUserSpins;
    spinedUsers: ISpinedUser;
    statistics: IStatistic[];
}

const wheelFortuneReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_ALL_WHEEL_SLOTS:
            return {
                ...state,
                slots: action.payload,
            };
        case SET_ALL_TOKENS:
            return {
                ...state,
                tokens: action.payload,
            };
        case SET_SINGLE_WHEEL_SLOTS:
            return {
                ...state,
                slot: action.payload,
            };
        case SET_ALL_REMAINING_SLOTS:
            return {
                ...state,
                remainingSlots: action.payload,
            };
        case SET_SLOTS_NO:
            return {
                ...state,
                slotsNo: action.payload,
            };
        case SET_USER_SPINS:
            return {
                ...state,
                userSpins: action.payload,
            };
        case SET_ALL_SPINED_USERS:
            return {
                ...state,
                spinedUsers: action.payload,
            };
        case SET_WHEEL_FORTUNE_STATISTICS:
            return {
                ...state,
                statistics: action.payload,
            };
        default:
            return state;
    }
};

export default wheelFortuneReducer;
