import 'react-app-polyfill/stable';
import 'core-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { storeInit } from './network/config';
import { INITIAL_APP_STATE } from './network/store/storeConstants';
import appReducers from './network/store/reducers';
import { initializeApp } from 'firebase/app';

if (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = storeInit('AUTH_LOGOUT', INITIAL_APP_STATE, appReducers);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
