import { IStoreAction } from 'network/config';
import {
    SET_FCM_USERS,
    SET_LIST_ALL_CAT_WITH_PERMISSIONS,
    SET_LOGIN_DATA,
    SET_SCHEDULED_NOTIFICATIONS,
    SET_SHOW_USER_PERMISSIONS,
    SET_SIDE_BAR,
    SET_USER_ROLE,
} from 'network/store/storeTypes';

export type TNotification = {
    title: string;
    body: string;
    date: string;
    image: string;
    repeated: number;
    updatedAt: string;
    createdAt: string;
};

export type TFcmUser = {
    _id: string;
    userId: string;
    channelName: string;
    email: string;
    fcmToken: string;
    createdAt: string;
    updatedAt: string;
    __v: string;
};
export interface IFcmUSers {
    users: TFcmUser[];
    count: number;
}
export interface INotificationsState {
    notifications: TNotification[];
    fcmUsers: IFcmUSers;
}

const notificationsReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_SCHEDULED_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };

        case SET_FCM_USERS:
            return {
                ...state,
                fcmUsers: action.payload,
            };
        default:
            return state;
    }
};

export default notificationsReducer;
