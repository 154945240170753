import { IStoreAction } from 'network/config';
import {
    SET_ADD_FLASH_LEARN_CONTENT,
    SET_EDIT_FLASH_LEARN_CONTENT,
    SET_FLASH_LEARN_CONTENT,
    SET_SINGLE_FLASH_LEARN_CONTENT,
} from '../storeTypes';

export type TFlashLearn = {
    _id: string;
    channelId: string;
    channelName: string;
    channelLogo: string;
    title: string;
    description: string;
    image?: string;
    video?: string;
    createdAt: string;
    updatedAt: string;
    views: number;
    dailyQuiz: boolean;
    arabic: {
        title: string;
        description: string;
    };
    deutsch: {
        title: string;
        description: string;
    };
};

export interface IFlashLearn {
    flashLearnContent: TFlashLearn[];
    addFlashLearnContent: TFlashLearn;
    singleFlashLearnContent: TFlashLearn;
}

const flashLearnReducer = (state = {}, action: IStoreAction) => {
    switch (action.type) {
        case SET_FLASH_LEARN_CONTENT:
            return {
                ...state,
                flashLearnContent: action.payload,
            };
        case SET_ADD_FLASH_LEARN_CONTENT:
            return {
                ...state,
                addFlashLearnContent: action.payload,
            };
        //
        case SET_SINGLE_FLASH_LEARN_CONTENT:
            return {
                ...state,
                singleFlashLearnContent: action.payload,
            };
        //
        case SET_EDIT_FLASH_LEARN_CONTENT:
            return {
                ...state,
                singleFlashLearnContent: action.payload,
            };
        default:
            return state;
    }
};

export default flashLearnReducer;
