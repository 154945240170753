export const AUTH = {
    LOGIN: 'auth/login-admin',
    GET_ROLE: 'user/profile/get-user-role',
    CHANGE_PASSWORD: 'user/profile/password/change',
    VERIFY_BY_ADMIN: 'auth/verify-by-admin',
};

export const LEADERBOARD = {
    LEADERBOARD_WITHOUT_CATEGORY:
        'leader-boards?parentFilter={{parentFilter}}&childFilter={{childFilter}}&isAdmin={{isAdmin}}',
    LEADERBOARD_WITH_CATEGORY:
        'leader-boards?parentFilter={{parentFilter}}&childFilter={{childFilter}}&category={{category}}&isAdmin={{isAdmin}}',
    GET_LEADERBOARD_INFORMATION: 'leader-boards/get-leaderboard-information',
    RESET_BALANCE: 'leader-boards/resetBalance?tokenId={{balanceToken}}',
    GET_CLAIMED_USERS:
        '/get-claimed-users?tokenId={{tokenId}}&claimed={{claimed}}&userStatus={{userStatus}}',
    MARK_AS_CLAIMED: '/mark-as-claimedV2',
};
export const FLASH_LEARN = {
    GET_FLASH_LEARN_CONTENT: 'flashlearn/get-flashlearn-by-cat?lang={{lang}}&category={{category}}',
    GET_SINGLE_CONTENT: `flashlearn/get-single-content?id={{id}}`,
};
export const WHEEL_FORTUNE = {
    GET_ALL_SLOTS: 'admin/wheel-fortune/get-all-slots?forCryptoChefsNft={{forCryptoChefsNft}}',
    GET_ALL_SPINED_USERS: 'admin/wheel-fortune/get-all-spined-users?page={{page}}&limit={{limit}}',
    GET_USER_SPINS: 'admin/wheel-fortune/get-user-spins?userId={{userId}}',
    GET_ALL_REMAINING_SLOTS:
        'wheel-fortune/get-wheel-fortune-config?forCryptoChefsNft={{forCryptoChefsNft}}',
    GET_SINGLE_SLOTS: 'admin/wheel-fortune/get-single-wheel-config?id={{id}}',
    ADD_NEW_SLOTS: 'admin/wheel-fortune/add-wheel-config',
    EDIT_SLOT: 'admin/wheel-fortune/edit-wheel-config?id={{id}}',
    DELETE_SLOT: 'admin/wheel-fortune/delete-wheel-config',
    GET_ALL_TOKENS: 'admin/tokens',
    GET_STATISTICS: 'admin/wheel-fortune/get-wheel-fortune-statistics',
};
export const ADMIN = {
    QUIZ_CATEGORIES: 'admin/quiz-categories',
    RESET_XP: '/admin/users/reset-xp',
    WALLETS: '/admin/wallets',
    ADD_FLASH_LEARN_CONTENT: 'admin/flashlearn/add-content',
    DELETE_CONTENT: '/admin/flashlearn/delete-content',
    GET_REWARDS: '/admin/rewards',
    GET_USERS_ADDRESSES: '/admin/wallets/get-users-addresses?walletId={{walletId}}',
    DELETE_REWARD: '/admin/rewards',
    DISTRIBUTE_REWARD: '/admin/rewards/distribute',
    DELETE_CATEGORY: '/admin/quiz-categories',
    DUPLICATED_ADDRESSES: '/admin/users/duplicated-addresses',
    LIST_ALL_CAT_WITH_PERMISSIONS: 'admin/rolesPermissions/listAllCatWithPermissions',
    SHOW_USER_PERMISSIONS: '/admin/rolesPermissions/showUserPermissions?userId={{userId}}',
    UPDATE_USER_PERMISSIONS: '/admin/rolesPermissions/updateUserPermissions?userId={{userId}}',
    CHANGE_USER_STATUS: '/admin/users/change-user-status',
    ADD_TRANSLATED_MSG: '/admin/translation/addTranslatedMsg',
    DELETE_TRANSLATED_MSG: '/admin/translation/deleteTranslatedMsg',
    UPDATE_TRANSLATED_MSG: '/admin/translation/updateTranslatedMsg',
    GET_TRANSLATED_MSG: '/user/translation/getTranslatedMsgs',
    GET_IP_ADDRESSES: '/admin/users/get-ip-addresses',
    GET_USER_CLAIMS: '/admin/users/get-user-claims?userId={{userId}}',
    GET_SCHEDULED_NOTIFICATIONS: '/admin/notification/get-scheduled-notifications',
    ADD_NOTIFY_CRON_JOB: '/admin/notification/add-notify-cron-job',
    DELETE_NOTIFY_CRON_JOB: '/admin/notification/delete-scheduled-notification',
    SEND_NOTIFICATION: '/admin/notification/send-fcm-notification',
    GET_FCM_USERS: '/admin/notification/get-fcm-users',
    SEND_EMAIL_FOR_INCORRECT_WALLETS: '/admin/users/sendIncorrectAddressEmail',
    SEND_EMAIL_FOR_INCORRECT_BINANCE_UID: '/admin/users/sendIncorrectBinanceUID',
    SEND_EMAIL_TO_INACTIVE_USERS: '/admin/users/send-email-for-inactive-users',
    RESET_REWARD_FOR_INCORRECT_ADDRESSES: '/admin/rewards/reset-reward-for-incorrect-addresses',
    GET_APP_STATUS: 'admin/settings/getAppStatusForAdmin',
    CHANGE_APP_STATUS: '/admin/settings/change-setting?_id={{_id}}',
};

export const QUESTIONS = {
    GET_ALL_QUESTIONS: 'admin/quiz-questions/get-all-questions?category={{category}}&lang={{lang}}',
    GET_SINGLE_QUESTIONs:
        'admin/quiz-questions/single-question?id={{id}}&lang={{lang}}&englishId={{englishId}}',
    DELETE_QUESTION: 'admin/quiz-questions/delete-question',
    GET_QUESTIONS_ANALYTICS: 'admin/question-analytic/get-questions-analytics',
    GET_REPORTED_QUESTIONS: 'admin/question-analytic/get-reported-questions',
    MARK_AS_REVIEWED: 'admin/question-analytic/mark-as-reviewed',
    GET_ACTIVE_QUIZZES: 'quizzes/getActiveQuizzes',
};

export const REFERRAL = {
    REFERRED_FRIENDS: 'referral/referred-friends?userId={{userId}}&isAdmin={{isAdmin}}',
    NEW_REFERRAL_REWARDS: 'admin/referral/new-referral-reward',
    GET_REFERRAL_REWARDS: 'admin/referral/get-referral-rewards',
    UPDATE_REFERRAL_REWARD: 'admin/referral/update-referral-reward?rewardId={{rewardId}}',
    DELETE_REFERRAL_REWARD: 'admin/referral/delete-referral-reward?rewardId={{rewardId}}',
    SINGLE_REFERRAL_REWARD: 'admin/referral/single-referral-reward?rewardId={{rewardId}}',
    REFERRED_USERS: 'admin/referral/referred-users',
    USER_REFERRAL_DETAILS: 'admin/referral/user-referral-details?userId={{userId}}',
    REG_USING_REF_CODE: '/admin/referral/registered-using-referral-code',
};
