/* eslint-disable prefer-template */
import API_CONFIG from '../storeConfig';
import { API } from '../storeTypes';
import {
    IApiPayload,
    ETokenTypes,
    IApiMiddlewareAction,
    TNetwork,
    TNetworkMulti,
    IApiMultiPayload,
} from '../storeInterface';
import axios, { AxiosResponse } from 'axios';

export const apiAction = ({
    url,
    isAbsoluteUrl,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    apiBase = 'CRYPTONAIRE',
    withToken = true,
    withReqCode,
    token,
    tokenType,
    headers,
    onFinaly,
}: IApiPayload) => {
    return {
        type: API,
        payload: {
            url,
            method,
            isAbsoluteUrl,
            data,
            onSuccess,
            onFailure,
            onFinaly,
            label,
            apiBase,
            withToken,
            withReqCode,
            token,
            tokenType,
            headers,
        },
    };
};

export const apiMultiAction = ({
    requests,
    label,
    onSuccess,
    onFailure,
    onFinaly,
}: IApiMultiPayload) => {
    return {
        type: API,
        payload: {
            requests,
            label,
            onSuccess,
            onFailure,
            onFinaly,
        },
    };
};

export const storeToken = (tokenValue: string, key: string) => {
    if (tokenValue) {
        setToLocalStorage(key, tokenValue);
    }
};

export const setToLocalStorage = (name: string, value: string) => {
    if (typeof window !== 'undefined') {
        name && value && window.localStorage.setItem(name, value);
    }
};

export const getFromLocalStorage = (name: string) => {
    if (typeof window !== 'undefined') {
        return window.localStorage.getItem(name);
    }
};

export const performNavigation = (pathname: string) => {
    if (window.top) {
        window.top.location.href = pathname;
    }
};

export const clearAuthentication = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('email');
};

export const replaceUrlVariables = (url: string, params: { [key: string]: any }): string => {
    const regex = /[^{{}]+(?=}})/g;
    const matches = url.match(regex);
    let modifiedURL = url;
    if (matches) {
        matches.forEach((item: string) => {
            const value = params[item];
            modifiedURL = modifiedURL.replace(new RegExp('{{' + item + '}}'), value);
        });
    }

    return modifiedURL;
};

export const getAuthorizationHeader = (token: string, tokenType: ETokenTypes = 'Bearer') => {
    if (token) {
        return `${tokenType} ${token}`;
    }
    return '';
};

export const setAuthorizationHeaderToAxios = (token: string, tokenType: ETokenTypes = 'Bearer') => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `${tokenType} ${token}`;
        return;
    }
    axios.defaults.headers.common['Authorization'] = '';
};

export const NetworkRequest = async ({
    apiBase = 'CRYPTONAIRE',
    url,
    method,
    data,
    withToken,
    token,
    isAbsoluteUrl = false,
    headers = {},
    tokenType,
}: TNetwork) => {
    const mustBeArray = url?.includes('resetBalance') || url.includes('reset-xp');
    const formatedUrl = url;
    const TOKEN = getFromLocalStorage('token');
    const { API_HEADERS_COMMON_CONFIG, DEFAULT_TOKEN_TYPE } = API_CONFIG;
    axios.defaults.baseURL = isAbsoluteUrl ? '/' : API_CONFIG.baseURL[apiBase];
    // Sets request "Headers" common attributes
    const finalHeaders = {
        ...headers,
        ...API_HEADERS_COMMON_CONFIG,
    };
    axios.defaults.headers.common = finalHeaders;
    // if token is provided
    if (token) {
        setAuthorizationHeaderToAxios(token, tokenType || 'Bearer');
        // eslint-disable-next-line brace-style
    }
    // if with Token provided bring token from localstorage
    else if (withToken && TOKEN && apiBase === 'CRYPTONAIRE') {
        setAuthorizationHeaderToAxios(TOKEN, DEFAULT_TOKEN_TYPE);
    } else {
        setAuthorizationHeaderToAxios('', DEFAULT_TOKEN_TYPE);
    }

    const dataOrParams = ['GET']?.includes(method) ? 'params' : 'data';

    axios.interceptors.response.use(handleSuccess, handleError);
    try {
        const response = await axios.request({
            url: formatedUrl,
            method,
            [dataOrParams]: mustBeArray ? Object.values(data) : data,
        });
        return Promise.resolve(response);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

// TODO: handle absolute url later
export const NetworkMultipleRequests = async ({ requests }: TNetworkMulti) => {
    // get Common headers
    const { API_HEADERS_COMMON_CONFIG, DEFAULT_TOKEN_TYPE } = API_CONFIG;
    // set base url

    const TOKEN = getFromLocalStorage('token');
    // iterate throught requests to build axios request and send them as Promise.all
    const transformedRequests = requests.map((request) => {
        const {
            url,
            method,
            data,
            withToken = true,
            token,
            headers,
            apiBase = 'CRYPTONAIRE',
            isAbsoluteUrl,
        } = request;
        const formatedUrl = url;
        axios.defaults.baseURL = isAbsoluteUrl ? '/' : API_CONFIG.baseURL[apiBase];
        const dataOrParams = ['GET'].includes(method) ? 'params' : 'data';

        let AuthorizationHeader = {
            ...headers,
            ...API_HEADERS_COMMON_CONFIG,
        };

        if (token) {
            AuthorizationHeader = getAuthorizationHeader(token, DEFAULT_TOKEN_TYPE);
            // if no token provided but wants to get it from local storage just pass withToken
        } else if (withToken && TOKEN && apiBase === 'CRYPTONAIRE') {
            AuthorizationHeader = getAuthorizationHeader(TOKEN, DEFAULT_TOKEN_TYPE);
        }
        const dataSection = data ? { [dataOrParams]: data } : {};
        const headersSection = AuthorizationHeader ? { Authorization: AuthorizationHeader } : {};
        const finalRequest: any = {
            url: formatedUrl,
            method,
            headers: {
                ...headersSection,
            },
            ...dataSection,
        };
        return axios.request(finalRequest).catch((error) => error);
    });

    const responses = await Promise.all(transformedRequests);
    const errors = responses
        .map((response) => (response.isAxiosError ? response.message : null))
        .filter((item) => !!item);
    if (errors.length > 0) {
        return Promise.reject([...errors]);
    }
    return Promise.resolve(responses);
};

const handleSuccess = (response: AxiosResponse<any>) => {
    const { message, error, data } = response.data;
    const { errorCode, message: dataMessage, token } = data || {};
    const messageSL = message ? message.toLowerCase() : message;

    if (messageSL !== 'success' && messageSL && !messageSL.includes('success') && !token) {
        return Promise.reject(message || error);
    } else if (errorCode) {
        return Promise.reject(dataMessage);
    }

    return Promise.resolve(response);
};

const handleError = (error: any) => {
    const errorResponseStatus = (error.response || {}).status || 0;
    const errorData = (error.response || {}).data || {};

    const { message, error: errorMessage } = errorData;
    console.log('errorMessage', message, errorResponseStatus);
    // TODO ADD NEW ERROR CODES FOR LOGOUT
    if (
        (error && error.response && [401].includes(errorResponseStatus)) ||
        error === 'login has expired'
    ) {
        if (process.env.NODE_ENV !== 'development') {
            clearAuthentication();
            // should be handled from one place which is inside auth
            // performNavigation("");
        }
    }
    if (/network\serror/gi.test(error)) {
        return Promise.reject(
            'Oops, something went wrong! Please check your network connection and try again.',
        );
    } else if (`${errorResponseStatus}`.charAt(0) === '6') {
        return Promise.reject('Oops, something went wrong! Please try again later');
    }
    return Promise.reject(message || errorMessage || error.message || error);
};
