//All types will be listed here
export const GET_LOGIN_CODE = 'GET_LOGIN_CODE';
export const SET_LOGIN_CODE = 'SET_LOGIN_CODE';

export const GET_LOGIN_DATA = 'GET_LOGIN_DATA';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const GET_CHANGE_PASSWORD = 'GET_CHANGE_PASSWORD';
export const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD';

export const GET_VERIFY_USER = 'GET_VERIFY_USER';
export const SET_VERIFY_USER = 'SET_VERIFY_USER';

export const GET_LIST_ALL_CAT_WITH_PERMISSIONS = 'GET_LIST_ALL_CAT_WITH_PERMISSIONS';
export const SET_LIST_ALL_CAT_WITH_PERMISSIONS = 'SET_LIST_ALL_CAT_WITH_PERMISSIONS';

export const GET_DISABLE_USERS = 'GET_DISABLE_USERS';
export const SET_DISABLE_USERS = 'SET_DISABLE_USERS';

export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

export const GET_SHOW_USER_PERMISSIONS = 'GET_SHOW_USER_PERMISSIONS';
export const SET_SHOW_USER_PERMISSIONS = 'SET_SHOW_USER_PERMISSIONS';

export const GET_UPDATE_USER_PERMISSIONS = 'GET_UPDATE_USER_PERMISSIONS';
export const SET_UPDATE_USER_PERMISSIONS = 'SET_UPDATE_USER_PERMISSIONS';

export const GET_LEADER_BOARD = 'GET_LEADER_BOARD';
export const SET_LEADER_BOARD = 'SET_LEADER_BOARD';

export const GET_QUIZ_CATEGORIES = 'GET_QUIZ_CATEGORIES';
export const SET_QUIZ_CATEGORIES = 'SET_QUIZ_CATEGORIES';

export const GET_MARK_AS_CLAIMED = 'GET_MARK_AS_CLAIMED';
export const SET_MARK_AS_CLAIMED = 'SET_MARK_AS_CLAIMED';

export const GET_ACTIVE_QUIZZES = 'GET_ACTIVE_QUIZZES';
export const SET_ACTIVE_QUIZZES = 'SET_ACTIVE_QUIZZES';

export const GET_LEADERBOARD_INFORMATION = 'GET_LEADERBOARD_INFORMATION';
export const SET_LEADERBOARD_INFORMATION = 'SET_LEADERBOARD_INFORMATION';

export const GET_CLAIMED_USERS = 'GET_CLAIMED_USERS';
export const SET_CLAIMED_USERS = 'SET_CLAIMED_USERS';

export const GET_REST_BALANCE = 'GET_REST_BALANCE';
export const SET_REST_BALANCE = 'SET_REST_BALANCE';

export const GET_REST_USER_XP = 'GET_REST_USER_XP';
export const SET_REST_USER_XP = 'SET_REST_USER_XP';

export const GET_ALL_WALLETS = 'GET_ALL_WALLETS';
export const SET_ALL_WALLETS = 'SET_ALL_WALLETS';

export const GET_ALL_TOKENS = 'GET_ALL_TOKENS';
export const SET_ALL_TOKENS = 'SET_ALL_TOKENS';

export const GET_USER_SPINS = 'GET_USER_SPINS';
export const SET_USER_SPINS = 'SET_USER_SPINS';

export const GET_ALL_SPINED_USERS = 'GET_ALL_SPINED_USERS';
export const SET_ALL_SPINED_USERS = 'SET_ALL_SPINED_USERS';

export const GET_DELETE_WHEEL_SLOT = 'GET_DELETE_WHEEL_SLOT';
export const SET_DELETE_WHEEL_SLOT = 'SET_DELETE_WHEEL_SLOT';

export const GET_ADD_NEW_SLOT = 'GET_ADD_NEW_SLOT';
export const SET_ADD_NEW_SLOT = 'SET_ADD_NEW_SLOT';

export const GET_EDIT_EDIT_SLOT = 'GET_EDIT_EDIT_SLOT';
export const SET_EDIT_EDIT_SLOT = 'SET_EDIT_EDIT_SLOT';

export const GET_FLASH_LEARN_CONTENT = 'GET_FLASH_LEARN_CONTENT';
export const SET_FLASH_LEARN_CONTENT = 'SET_FLASH_LEARN_CONTENT';

export const GET_SINGLE_FLASH_LEARN_CONTENT = 'GET_SINGLE_FLASH_LEARN_CONTENT';
export const SET_SINGLE_FLASH_LEARN_CONTENT = 'SET_SINGLE_FLASH_LEARN_CONTENT';

export const GET_EDIT_FLASH_LEARN_CONTENT = 'GET_EDIT_FLASH_LEARN_CONTENT';
export const SET_EDIT_FLASH_LEARN_CONTENT = 'SET_EDIT_FLASH_LEARN_CONTENT';

export const GET_ADD_FLASH_LEARN_CONTENT = 'GET_ADD_FLASH_LEARN_CONTENT';
export const SET_ADD_FLASH_LEARN_CONTENT = 'SET_ADD_FLASH_LEARN_CONTENT';

export const SET_DELETE_FLASH_LEARN_CONTENT = 'SET_DELETE_FLASH_LEARN_CONTENT';
export const GET_DELETE_FLASH_LEARN_CONTENT = 'GET_DELETE_FLASH_LEARN_CONTENT';

export const GET_ALL_WHEEL_SLOTS = 'GET_ALL_WHEEL_SLOTS';
export const SET_ALL_WHEEL_SLOTS = 'SET_ALL_WHEEL_SLOTS';

export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const SET_ALL_QUESTIONS = 'SET_ALL_QUESTIONS';
export const GET_SINGLE_QUESTIONS = 'GET_SINGLE_QUESTIONS';
export const SET_SINGLE_QUESTIONS = 'SET_SINGLE_QUESTIONS';

export const GET_DELETE_QUESTION = 'GET_DELETE_QUESTION';
export const SET_DELETE_QUESTION = 'SET_DELETE_QUESTION';

export const GET_SINGLE_WHEEL_SLOTS = 'GET_SINGLE_WHEEL_SLOTS';
export const SET_SINGLE_WHEEL_SLOTS = 'SET_SINGLE_WHEEL_SLOTS';

export const GET_ALL_REMAINING_SLOTS = 'GET_ALL_REMAINING_SLOTS';
export const SET_ALL_REMAINING_SLOTS = 'SET_ALL_REMAINING_SLOTS';

export const GET_WHEEL_FORTUNE_STATISTICS = 'GET_WHEEL_FORTUNE_STATISTICS';
export const SET_WHEEL_FORTUNE_STATISTICS = 'SET_WHEEL_FORTUNE_STATISTICS';

export const GET_QUESTIONS_ANALYTICS_DETAILS = 'GET_QUESTIONS_ANALYTICS_DETAILS';
export const SET_QUESTIONS_ANALYTICS_DETAILS = 'SET_QUESTIONS_ANALYTICS_DETAILS';

export const GET_REPORTED_QUESTIONS = 'GET_REPORTED_QUESTIONS';
export const SET_REPORTED_QUESTIONS = 'SET_REPORTED_QUESTIONS';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export const GET_SEND_EMAIL_FOR_INCORRECT_WALLETS = 'GET_SEND_EMAIL_FOR_INCORRECT_WALLETS';
export const SET_SEND_EMAIL_FOR_INCORRECT_WALLETS = 'SET_SEND_EMAIL_FOR_INCORRECT_WALLETS';

export const GET_SEND_EMAIL_FOR_INCORRECT_BINANCE_UID = 'GET_SEND_EMAIL_FOR_INCORRECT_BINANCE_UID';
export const SET_SEND_EMAIL_FOR_INCORRECT_BINANCE_UID = 'SET_SEND_EMAIL_FOR_INCORRECT_BINANCE_UID';

export const GET_SEND_EMAIL_TO_INACTIVE_USERS = 'GET_SEND_EMAIL_TO_INACTIVE_USERS';
export const SET_SEND_EMAIL_TO_INACTIVE_USERS = 'SET_SEND_EMAIL_TO_INACTIVE_USERS';

export const GET_RESET_WRONG_ADDRESSES_REWARD = 'GET_RESET_WRONG_ADDRESSES_REWARD';
export const SET_RESET_WRONG_ADDRESSES_REWARD = 'SET_RESET_WRONG_ADDRESSES_REWARD';

export const GET_DELETE_USER = 'GET_DELETE_USER';
export const SET_DELETE_USER = 'SET_DELETE_USER';

export const GET_ALL_USERS_DETAILS = 'GET_ALL_USERS_DETAILS';
export const SET_ALL_USERS_DETAILS = 'SET_ALL_USERS_DETAILS';

export const GET_REFERRED_BY_INFLUENCER_DETAILS = 'GET_REFERRED_BY_INFLUENCER_DETAILS';
export const SET_REFERRED_BY_INFLUENCER_DETAILS = 'SET_REFERRED_BY_INFLUENCER_DETAILS';

export const GET_ALL_IP_ADDRESSES = 'GET_ALL_IP_ADDRESSES';
export const SET_ALL_IP_ADDRESSES = 'SET_ALL_IP_ADDRESSES';

export const GET_DUPLICATED_WALLETS = 'GET_DUPLICATED_WALLETS';
export const SET_DUPLICATED_WALLETS = 'SET_DUPLICATED_WALLETS';

export const GET_USER_QUIZ_ATTEMPTS = 'GET_USER_QUIZ_ATTEMPTS';
export const SET_USER_QUIZ_ATTEMPTS = 'SET_USER_QUIZ_ATTEMPTS';

export const GET_UPDATE_USER_BALANCE = 'GET_UPDATE_USER_BALANCE';
export const SET_UPDATE_USER_BALANCE = 'SET_UPDATE_USER_BALANCE';

export const GET_MARK_REPORTED_QUESTIONS_AS_REVIEWED = 'GET_MARK_REPORTED_QUESTIONS_AS_REVIEWED';
export const SET_MARK_REPORTED_QUESTIONS_AS_REVIEWED = 'SET_MARK_REPORTED_QUESTIONS_AS_REVIEWED';

//Rewards Actions
export const GET_USERS_WALLET_ADDRESSES = 'GET_USERS_WALLET_ADDRESSES';
export const SET_USERS_WALLET_ADDRESSES = 'SET_USERS_WALLET_ADDRESSES';

export const GET_ALL_REWARDS_DETAILS = 'GET_ALL_REWARDS_DETAILS';
export const SET_ALL_REWARDS_DETAILS = 'SET_ALL_REWARDS_DETAILS';

export const GET_DELETE_REWARD = 'GET_DELETE_REWARD';
export const SET_DELETE_REWARD = 'SET_DELETE_REWARD';

export const GET_DELETE_CATEGORY = 'GET_DELETE_CATEGORY';
export const SET_DELETE_CATEGORY = 'SET_DELETE_CATEGORY';

export const GET_EDIT_SINGLE_REWARD = 'GET_EDIT_SINGLE_REWARD';
export const SET_EDIT_SINGLE_REWARD = 'SET_EDIT_SINGLE_REWARD';

export const GET_ADD_SINGLE_REWARD = 'GET_ADD_SINGLE_REWARD';
export const SET_ADD_SINGLE_REWARD = 'SET_ADD_SINGLE_REWARD';

export const GET_DISTRIBUTE_REWARD = 'GET_DISTRIBUTE_REWARD';
export const SET_DISTRIBUTE_REWARD = 'SET_DISTRIBUTE_REWARD';

//Referral
export const GET_REFERRED_FRIENDS = 'GET_REFERRED_FRIENDS';
export const SET_REFERRED_FRIENDS = 'SET_REFERRED_FRIENDS';

export const GET_REFERRAL_REWARDS = 'GET_REFERRAL_REWARDS';
export const SET_REFERRAL_REWARDS = 'SET_REFERRAL_REWARDS';

export const GET_SINGLE_REFERRAL_REWARD = 'GET_SINGLE_REFERRAL_REWARD';
export const SET_SINGLE_REFERRAL_REWARD = 'SET_SINGLE_REFERRAL_REWARD';

export const GET_REGISTERED_USERS_USING_REFERRAL_CODE = 'GET_REGISTERED_USERS_USING_REFERRAL_CODE';
export const SET_REGISTERED_USERS_USING_REFERRAL_CODE = 'SET_REGISTERED_USERS_USING_REFERRAL_CODE';

export const GET_NEW_REFERRAL_REWARD = 'GET_NEW_REFERRAL_REWARD';
export const SET_NEW_REFERRAL_REWARD = 'SET_NEW_REFERRAL_REWARD';

export const GET_REFERRED_USERS = 'GET_REFERRED_USERS';
export const SET_REFERRED_USERS = 'SET_REFERRED_USERS';

export const GET_USER_REFERRAL_DETAILS = 'GET_USER_REFERRAL_DETAILS';
export const SET_USER_REFERRAL_DETAILS = 'SET_USER_REFERRAL_DETAILS';

export const GET_USER_CLAIM_DETAILS = 'GET_USER_CLAIM_DETAILS';
export const SET_USER_CLAIM_DETAILS = 'SET_USER_CLAIM_DETAILS';

export const SET_ADD_EDIT_REFERRAL_REWARD_ERROR = 'SET_ADD_EDIT_REFERRAL_REWARD_ERROR';

export const SET_ADD_EDIT_REFERRAL_REWARD_SUCCESS = 'SET_ADD_EDIT_REFERRAL_REWARD_SUCCESS';

export const GET_EDIT_REFERRAL_REWARD = 'GET_EDIT_REFERRAL_REWARD';
export const SET_EDIT_REFERRAL_REWARD = 'SET_EDIT_REFERRAL_REWARD';

export const GET_DELETE_REFERRAL_REWARD = 'GET_DELETE_REFERRAL_REWARD';
export const SET_DELETE_REFERRAL_REWARD = 'SET_DELETE_REFERRAL_REWARD';

// Messages Translation

export const GET_ALL_TRANSLATED_MSGS = 'GET_ALL_TRANSLATED_MSGS';
export const SET_ALL_TRANSLATED_MSGS = 'SET_ALL_TRANSLATED_MSGS';

export const GET_ADD_TRANSLATED_MESSAGE = 'GET_ADD_TRANSLATED_MESSAGE';
export const SET_ADD_TRANSLATED_MESSAGE = 'SET_ADD_TRANSLATED_MESSAGE';

export const GET_UPDATE_TRANSLATED_MESSAGE = 'GET_UPDATE_TRANSLATED_MESSAGE';
export const SET_UPDATE_TRANSLATED_MESSAGE = 'SET_UPDATE_TRANSLATED_MESSAGE';

export const GET_DELETE_TRANSLATED_MESSAGE = 'GET_DELETE_TRANSLATED_MESSAGE';
export const SET_DELETE_TRANSLATED_MESSAGE = 'SET_DELETE_TRANSLATED_MESSAGE';

//NOTIFICATIONS ACTIONS
export const GET_SCHEDULED_NOTIFICATIONS = 'GET_SCHEDULED_NOTIFICATIONS';
export const SET_SCHEDULED_NOTIFICATIONS = 'SET_SCHEDULED_NOTIFICATIONS';

export const GET_ADD_NOTIFY_CRON_JOB = 'GET_ADD_NOTIFY_CRON_JOB';
export const SET_ADD_NOTIFY_CRON_JOB = 'SET_ADD_NOTIFY_CRON_JOB';

export const GET_SEND_NOTIFICATION = 'GET_SEND_NOTIFICATION';
export const SET_SEND_NOTIFICATION = 'SET_SEND_NOTIFICATION';

export const GET_FCM_USERS = 'GET_FCM_USERS';
export const SET_FCM_USERS = 'SET_FCM_USERS';

export const GET_DELETE_NOTIFY_CRON_JOB = 'GET_DELETE_NOTIFY_CRON_JOB';
export const SET_DELETE_NOTIFY_CRON_JOB = 'SET_DELETE_NOTIFY_CRON_JOB';
export const GET_APP_STATUS = 'GET_APP_STATUS';
export const SET_APP_STATUS = 'SET_APP_STATUS';

export const GET_CHANGE_APP_STATUS = 'GET_CHANGE_APP_STATUS';
export const SET_CHANGE_APP_STATUS = 'SET_CHANGE_APP_STATUS';

export const SET_QUESTIONS_PAGE_NUMBER = 'SET_QUESTIONS_PAGE_NUMBER';

export const SET_SLOTS_NO = 'SET_SLOTS_NO';

export const SET_SIDE_BAR = 'SET_SIDE_BAR';

export const AUTH_VERIFY = 'AUTH_VERIFY';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
