import { EMAIL, PLATFORM, TOKEN } from '../storeConstants';
import { IAuthState, IStoreAction } from '../storeInterface';
import { AUTH_LOGIN } from 'network/store/storeTypes';

const INITIAL_STATE = {
    token: TOKEN,
    email: EMAIL,
    platform: PLATFORM,
} as IAuthState;
const authReducer = (state = INITIAL_STATE, { type, payload }: IStoreAction) => {
    switch (type) {
        case AUTH_LOGIN:
            // eslint-disable-next-line no-case-declarations
            let isVerified = false;
            // eslint-disable-next-line no-case-declarations
            const { user } = payload;
            if (user) {
                const { verified } = user || {};
                isVerified = verified;
            }
            return {
                ...state,
                data: payload,
                isAuthenticated: true,
                isVerified,
            };
        default:
            return state;
    }
};

export default authReducer;
